// vue js https://vuejs.org/
// axios https://github.com/axios/axios
// vuex https://vuex.vuejs.org
// vue good table https://github.com/xaksis/vue-good-table
//js2excel json to excel https://github.com/dwqs/js2excel
//vuelidate for form validation https://vuelidate.js.org/
//vuetify for ui https://vuetifyjs.com/en/getting-started/quick-start
//vueter for manage routes/url https://router.vuejs.org/
//material icon for icon https://material.io/resources/icons/?style=baseline
// import the styles

//vue.mixins in this .js is used as a global mixins veriable which is used ion each and every components
import Vue from "vue";
import App from "./App.vue";
import router from "./routes/router.js";
import vuetify from "./plugins/vuetify";
import VueGoodTablePlugin from "vue-good-table";

import "leaflet/dist/leaflet.css";
import VueApexCharts from "vue-apexcharts";

import "./assets/styles/global.scss";

import "vue-good-table/dist/vue-good-table.css";
import Vuelidate from "vuelidate";
import axios from "axios";
import store from "./store";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import i18n from "./i18n";
require("@/store/subscriber");

Vue.use(store);
Vue.use(Vuelidate);
Vue.use(VueGoodTablePlugin);

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

Vue.use(vuetify);
Vue.use(require("vue-moment"));

Vue.config.productionTip = false;

let isRefreshing = false;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const {
      config,
      response: { status, data },
    } = err;
    const originalRequest = config;
    console.log(originalRequest);
    if (status === 401) {
      // router.push({ name: "userlogin" });
      console.log("un error");
      return Promise.reject(false);
    }
    if (status === 400 && data.State === "AccessTokenExpired") {
      localStorage.removeItem("token");
      if (!isRefreshing) {
        isRefreshing = true;
        store
          .dispatch("REFRESH_TOKEN")
          .then(({ status }) => {
            if (status === 200) {
              //originalRequest.headers.Authorization = `Bearer ${data.data.token}`;
              isRefreshing = false;
              window.location.reload();
            }
          })
          .catch((error) => {
            console.error("dispatch error all right", { error });
          });
      }
    }
  }
);

//axios.interceptors.response.use(function (response) {
//    // Any status code that lie within the range of 2xx cause this function to trigger
//    // Do something with response data
//    // store.dispatch('refreshToken',store.getters.getRefreshToken);
//    return response;
//}, function (error) {
//    // Any status codes that falls outside the range of 2xx cause this function to trigger
//    // Do something with response error
//    console.log(error.response)
//        if(error.response.data.State == "AccessTokenExpired"){
//            store.dispatch('refreshToken',store.getters.getRefreshToken);
//        }

//    return Promise.reject(false);
//});

Vue.mixin({
  data() {
    return {
      tokenData: JSON.parse(localStorage.getItem("tokenData")),
      userData: JSON.parse(localStorage.getItem("userInfo")),
    };
  },
  methods: {
    removeStorageData() {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenData");
      localStorage.removeItem("userInfo");
      delete axios.defaults.headers.common["Authorization"];
      return;
    },
  },
});

// Add a response interceptor
// axios.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     console.log("global" + response.data)
//     return response;
// }, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     // if (error.response.status === 403) {
//     //     router.push('/404')
//     // }
//     return Promise.reject(error);
// });

//Vue.http.options.root = 'https://localhost:44350/api/';
//Vue.http.headers.common['Content-Type'] = 'application/json';
//axios.defaults.baseURL = 'http://10.10.92.165:4444/api/';
/*http://103.69.126.115/*/
axios.defaults.baseURL = "https://cetmis.tourism.gov.np/api/";
axios.defaults.headers.common["Content-Type"] = "application/json";
store.dispatch("language", false);
//check authentication when refresh the page
store.dispatch("attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    store,
    router,
    vuetify,

    $_veeValidate: {
      validator: "new",
    },

    i18n,
    render: (h) => h(App),
  }).$mount("#app");
});

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
//import '@fortawesome/fontawesome-free/css/all.css'
//import 'material-design-icons-iconfont/dist/material-design-icon.css'
import colors from 'vuetify/lib/util/colors';
import vueGoogleCharts from 'vue-google-charts'

Vue.use(Vuetify);
Vue.use(vueGoogleCharts)

export default new Vuetify({
    icons: {
        iconfont: 'md' || 'fa' // 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    },
    theme: {
        themes: {
            light: {
                background: colors.blue.accent2,
            },
            dark: {
                background: colors.blue,
            }
        },
        }
});

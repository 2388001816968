<template>
        <v-fade-transition mode="out-in">
            <router-view />
        </v-fade-transition>
</template>
<script>
    export default {
        components: {},
        data: () => ({
            drawer: null
        }),
        computed: {},
        mounted() {
            this.debugStatus()
        },
        methods: {
            debugStatus() {
                // true for debug on
                var DEBUG = true;
                if (!DEBUG) {
                    if (!window.console) window.console = {};
                    var methods = ["log", "debug", "warn", "info", "error"];
                    for (var i = 0; i < methods.length; i++) {
                        console[methods[i]] = function () { };
                    }
                }
            },
        }
    };
</script>

<style lang="scss">
    .vgt-table thead th {
        background: #1976d2 !important;
        color: #ffffff !important;
    }

    .vgt-wrap__footer {
        background: #1976d2 !important;
        color: #ffffff !important;

        span.footer__row-count__label, .footer__navigation__info, select {
            color: #ffffff !important;
            background-color: #265b93 !important;
        }
    }

    ::v-deep .v-select__slot, ::v-deep .v-input__slot {
        min-height: 35px !important;
    }

    ::v-deep .v-list-item {
        background-color: #265b9300 !important;

        &:hover {
            background-color: #ffffff !important;
            color: #ffffff !important;
        }
    }

    .img-thumbnail {
        border-radius: 50%;
    }
    .border {
        border: 1px solid black !important;
    }
    .vgt-wrap__footer .footer__navigation__page-btn {
        text-decoration: none;
        color: #ffffff !important;
        font-weight: 700;
        white-space: nowrap;
    }
    .vgt-wrap__footer .footer__navigation__page-info {
        color: #ffffff !important;
    }
</style>
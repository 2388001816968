

//
import Vue from "vue";
import store from '@/store'
import Router from 'vue-router';
// Routes
import paths from './paths'
Vue.use(Router);

const router = new Router({
    base: '/',
    mode: 'history',
    routes: paths,
})
router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) {
        if (!store.getters['authenticated']) {
            return next('/homepage')
        }
        else { 
            // console.log(`from name ${from.name}`)
            // if(`${from.name}` == "userlogin")
            next()
        }
    } else {
        next()
    }
    if (to.meta.conditionalAuth) {
        if (store.getters['authenticated']) {
            next()
        }
        else {
            return next('/')
        }
    } else {
        next()
    }

    if (to.meta.ministry) {

        if (JSON.parse(localStorage.getItem("tokenData")).role == "Ministry") {
            next()
        }
        else if (JSON.parse(localStorage.getItem("tokenData")).role == "Branch") {
            return next('/branch')
        }
        else {
            // console.log(`from name ${from.name}`)
            // if(`${from.name}` == "userlogin")
            return next('/404')
        }
    } else {
        next()
    }
    if (to.meta.ministry) {

        if (JSON.parse(localStorage.getItem("tokenData")).role == "Ministry") {
            next()
        }
        else {
            // console.log(`from name ${from.name}`)
            // if(`${from.name}` == "userlogin")
            return next('/404')
        }
    } else {
        next()
    }
})
export default router
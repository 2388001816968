import axios from 'axios'
import router from "../routes/router";
// import {ref} from "vuelidate/lib/validators/common";
import VueI18n from "../i18n.js";

export default {
    //after signin clicked the process comes in sign in
    async signIn({ dispatch }, credential) {

        const headers = { 'Content-Type': 'application/json' }

        try {
            //api called for credential check and getting token
            let response = await axios.post('Account/Login', credential, { headers: headers })
            //called attempt method and send token from the response
            console.log('response.data.data', response.data.data)
            dispatch('attempt', response.data.token);
            dispatch('userData', response.data.data);
            dispatch('setAllData', response.data.data);
        }
        catch (e) {
            return (e);
        }
    },
    async attempt({ commit, state }, token) {
        //console.log(axios.defaults.headers,token)
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        if (token) {
            //called set token method to save token in local storage
            commit('setToken', token)
            commit('setTokenData', token)

        }
        if (!state.token) {
            localStorage.removeItem('token')
            localStorage.removeItem('userInfo')
            localStorage.removeItem('tokenData')
            delete axios.defaults.headers.common['Authorization']
            return
        }
    },
    async userData({ commit, state }, userInfo) {
        if (userInfo) {
            commit('setUserInfo', userInfo)
        }
        if (!state.userInfo) {
            return
        }
    },
    async refreshToken({ dispatch }, refreshToken) {
        if (refreshToken) {
            try {
                delete axios.defaults.headers.common['Authorization']

                axios.post('Account/RefreshToken', {
                    "granttype": "refresh_token",
                    "RefreshToken": JSON.parse(localStorage.getItem('userInfo')).refreshToken
                }).then(response => {
                    let ok = JSON.parse(localStorage.getItem('userInfo'))
                    ok.refreshToken = response.data.data.refresh_token
                    ok.expirationToken = response.data.data.expiration
                    localStorage.setItem('userInfo', JSON.stringify(ok))
                    localStorage.removeItem('refreshToken')
                    localStorage.setItem('refreshToken', JSON.stringify(response.data.data.refresh_token))
                    localStorage.setItem('token', response.data.data.token)
                    console.log(localStorage.getItem('token'), response.data.data.token)
                    dispatch('attempt', response.data.data.token);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.data.token}`

                    // axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`
                }).catch(err => {
                    console.log(err.response)
                })
            }
            catch (e) {
                return e
            }

        }
        //
        //     console.log("token",response)
        //     // if(response.data.success){
        //     //         commit('setRefreshToken', response.data.data.token)
        //     // }
        // }
        // if (!state.refreshToken) {
        //     return
        // }
    },
    async setAllData({ commit, state }, data) {
        if (data) {
            commit('setAllData', data)
        }
        if (!state.refreshToken) {
            return
        }
    },
    logout({ commit }) {
        return new Promise((resolve) => {
            axios.post('Account/Logout').then(response => {
                if (response) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('userInfo')
                    localStorage.removeItem('tokenData')
                    delete axios.defaults.headers.common['Authorization']

                }
            }).catch(e => {
                console.log(e)
            })
            commit('logout')
            resolve()
        })
    },
    REFRESH_TOKEN: () => {
        delete axios.defaults.headers.common['Authorization']
        return new Promise((resolve) => {
            axios
                .post(`Account/RefreshToken`, {
                    "granttype": "refresh_token",
                    "RefreshToken": JSON.parse(localStorage.getItem('refreshToken'))
                })
                .then(response => {
                    localStorage.removeItem('refreshToken')
                    localStorage.setItem('refreshToken', JSON.stringify(response.data.data.refresh_token))
                    localStorage.setItem('token', response.data.data.token)
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.data.token}`
                    resolve(response);
                })
                .catch(() => {
                    localStorage.clear();
                    delete axios.defaults.headers.common['Authorization']
                    router.push('/homepage')
                });
        });
    },
    language({ commit }, isLoad = true) {
        let lang = localStorage.getItem('lang') === 'ne' ? 'नेपाली' : 'English'
        if (isLoad) {
            const locale = lang === 'नेपाली' ? 'en' : 'ne'
            if (lang === "नेपाली") {
                lang = "English"
                document.title = "MOCTCA CETMIS"

            } else {
                lang = "नेपाली"
                document.title = "केन्द्रीकृत इलेक्ट्रॉनिक पर्यटन प्रबंधन सूचना प्रणाली"

            }
            VueI18n.locale = locale;
            localStorage.setItem('lang', locale)
        }
        commit('setLanguage', lang)
    },
}
export default {
    authenticated(state) {
        return state.token
    },
    getInfo(state) {
        return state.userInfo
    },
    getTokenInfo(state) {
        return state.tokenData
    },
    getUserData(state) {
        let returnData = JSON.parse(state.userInfo)
        let tokenData = JSON.parse(state.tokenData)
        return { ...returnData, ...tokenData }
    },
    getSystemUserData(state) {
        return JSON.parse(state.userInfo)
    },
    /*
    getSidebarMenu(state) {
        let userData = JSON.parse(state.userInfo)
        let returnData = userData?userData.menuList:''
        return returnData[0]?returnData[0].menuList:''
    },*/
    getDashboardLink(state) {
        return JSON.parse(state.userInfo).dashBoardBranchUser
    },
    getRefreshToken(state) {
        let userInfo = JSON.parse(state.userInfo)
        return userInfo.refreshToken
    },
    getLanguage(state) {
        return state.lang
    }
}
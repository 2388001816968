import store from '@/store'
import axios from 'axios'
import router from "../routes/router";
store.subscribe((mutation) => {

    //comes when mutation state.token in auth.js

    switch (mutation.type) {
        case 'setToken': {
            //mutation.payload contain token
            if (mutation.payload) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`
                //token store in local storage
                localStorage.setItem('token', mutation.payload)
                if (localStorage.getItem('token')) {
                    var base64Url = localStorage.getItem('token').split('.')[1];
                    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    }).join(''));
                    localStorage.setItem('tokenData', jsonPayload)
                }

                //redirect after successful login
            }
            break
        }
        case 'setUserInfo': {

            if (mutation.payload) {
                if (localStorage.getItem('userInfo')) {
                    break
                }
                else {
                    localStorage.setItem('userInfo', JSON.stringify(mutation.payload))
                    localStorage.setItem('refreshToken', JSON.stringify(mutation.payload.refreshToken))
                }
            }
                let redirectData = JSON.parse(localStorage.getItem('tokenData'))
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))

                if (redirectData.role === "Province") {
                    router.push(`${userInfo.dashBoardBranchUser}`)
                }
                else if (redirectData.role === "User") {
                    router.push(`${userInfo.dashBoardBranchUser}`)
                }
                else if (redirectData.role === "Ministry") {
                    router.push('/MinistryDashboard')
                }
                else if (redirectData.role === "Division") {
                    router.push(`${userInfo.dashBoardBranchUser}`)
                }

                else if (redirectData.role === "ProvinceModule") {
                    router.push(`${userInfo.dashBoardBranchUser}`)
                }
                else if (redirectData.role === "Association") {
                    router.push(`${userInfo.dashBoardBranchUser}`)
                }
                else if (redirectData.role === "ParentDivision") {
                    router.push(`${userInfo.dashBoardBranchUser}`)
                }
                else if (redirectData.role === "Branch") {

                        router.push(`${userInfo.dashBoardBranchUser}`)
                }
                else {
                    router.push('/404')
                }
            break
        }
        case 'setRefreshToken': {
            if (mutation.payload) {
                if (localStorage.getItem('refreshToken')) {
                    break
                }
                else {
                    localStorage.setItem('refreshToken', mutation.payload)
                }
            }
        }
    }
})